@tailwind base;
@tailwind components;
@tailwind utilities;

.pagination {
  @apply inline-block;
}

.pagination li {
  @apply mx-1;
  @apply inline-block;
  @apply rounded-full;
}

.pagination li a {
  @apply p-2;
  @apply inline-block;
  @apply shadow-lg;
  @apply rounded-full;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Railline:before {
  background-color: transparent;
  border-radius: 10px;
  content: " ";
  display: block;
  margin-right: 8px;
  height: 10px;
  width: 10px;
}
